@import "../../../../styles/variables";

.ib-dialog-suggested-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: @ib-padding-xxxx-small;

  &__item {
    background-color: @ib-white-color;
    border-radius: @ib-border-radius-x-large;
    border: 1px solid @gray-gray-100;
    padding: 0 @ib-padding-small;

    &_selected {
      background-color: @blue-blue-50;
    }

    &__text {
      color: @ib-font-color-secondary;
      line-height: @ib-line-height-x-large;
      font-size: @ib-font-size-small;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;