@import "../../../styles/variables";

.ib-dialog-history {
  &__messages {
    display: flex;
    flex-direction: column-reverse;
    flex: auto;
    overflow: auto;
    width: 70%;
    height: 100%;
    padding: @ib-padding-base @ib-padding-xx-small @ib-padding-base @ib-padding-base;
    margin: 0 @ib-padding-xx-small;

    .infinite-scroll-component {
      display: flex;
      flex-direction: column-reverse;
      overflow: hidden !important;
    }
  }

  &__info {
    padding: 0 @ib-padding-xx-small;
  }

  &_empty {
    text-align: center;
    align-self: center;
    width: 100%;

    .ib-icon {
      color: @ib-font-color-base;
      opacity: 0.2;
    }

    .ib-spin {
      span {
        font-size: 64px;
      }
    }

    div:not(.ib-spin) {
      margin-top: @ib-padding-base;
      color: @ib-font-color-secondary;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;