@import "../../styles/variables";
@import "../../styles/mixins";

.ib-search {
  font-size: @ib-font-size-small;
  line-height: @ib-line-height-base;
  color: @gray-gray-900;

  &__control {
    display: flex;
    align-items: center;
    padding: @ib-padding-xx-small;
    gap: @ib-padding-xx-small;
    height: @ib-element-size-40;
    border: 1px solid @gray-gray-200;
    border-radius: @ib-border-radius-x-small;
    background: @white-white-50;
    transition: all 0.2s ease-out;

    &__window {
      flex-grow: 1;
      overflow-x: hidden;

      &__content {
        display: flex;
        gap: @ib-padding-xx-small;

        &__input {
          flex-grow: 1;
          min-width: @ib-element-size-16;
          display: inline-grid;
          align-items: center;
          justify-items: start;

          > input {
            grid-area: 1 e("/") 1 e("/") 2 e("/") 2;
            width: 100%;
            border: none;
            outline: none;
            appearance: none;
            padding: 0;
          }

          > span {
            grid-area: 1 e("/") 1 e("/") 2 e("/") 2;
            visibility: hidden;
          }

          &:first-child {
            margin-left: @ib-padding-xx-small;
          }
        }
      }
    }

    &__clear-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: none;
      background: none;
      padding: 0;
    }

    &:hover,
    &:focus-within {
      border-color: @blue-blue-700;
    }
  }

  &__scroll {
    height: @ib-element-size-16;
    overflow-x: auto;

    & > div {
      height: @ib-element-size-8;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;