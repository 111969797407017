@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.ib-dialog-list-item {

  .ib-checkbox {
    flex-shrink: 0;
  }

  &__message {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      gap: @ib-padding-xxx-small;
      align-items: center;

      &__title {
        font-weight: @ib-font-weight-bold;
        font-size: @ib-font-size-base;
        line-height: @ib-line-height-base;
        color: @gray-gray-900;
        flex-grow: 1;
        .text-ellipsis
      }

      &__time {
        font-size: @ib-font-size-x-small;
        line-height: @ib-line-height-x-small;
        color: @gray-gray-300;
        flex-shrink: 0;
      }
    }

    &__footer {
      display: flex;

      &__text {
        .text-ellipsis(2);
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-base;
        flex-grow: 1;

        .ib-typography__paragraph_disabled {
          display: inline;
          position: relative;
          margin-right: @ib-padding-xxxxx-small;
          max-width: 120px;
          .text-ellipsis();
        }
      }

      &__badge {
        align-content: flex-end;

        .ib-badge {
          background: @gray-gray-100;
          color: @gray-gray-400;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;