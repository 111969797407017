@import "../../../../styles/variables";

.ib-dialog-message-info {
  height: calc(100vh - 70px);
  overflow: auto;
  padding: @ib-padding-large;

  &__title {
    font-size: @ib-font-size-large;
    font-weight: @ib-font-weight-bold;
    margin-bottom: @ib-padding-large;
    color: @ib-font-color-base;
  }

  &__frame {
    border-radius: @ib-border-radius-x-small;
    padding: @ib-padding-large;
    margin-bottom: @ib-padding-large;
    background: @orange-orange-50;

    &__text {
      margin-top: @ib-padding-base;
      margin-bottom: @ib-padding-xx-small;
      line-height: @ib-line-height-large;
    }
  }

  &__article {
    display: flex;
    align-items: center;
    font-size: @ib-font-size-large;
    line-height: @ib-line-height-large;
    margin-bottom: @ib-padding-small;
    margin-top: @ib-padding-large;
    color: @ib-font-color-base;

    .ib-icon {
      margin-right: @ib-padding-xx-small;
      color: @gray-gray-900 !important;
    }
  }

  &__article-frame {
    border: 1px solid @gray-gray-100;
    border-radius: @ib-border-radius-x-large;
    padding: @ib-padding-medium @ib-padding-base;
    background-color: @gray-gray-50;

    :not(:last-child) {
      margin-bottom: @ib-padding-xx-small;
    }
  }

  &__suggested-actions {
    display: flex;
    flex-wrap: wrap;
    gap: @ib-padding-xx-small;

    &__item {
      border: 1px solid @gray-gray-100;
      border-radius: @ib-border-radius-xx-large;
      padding: @ib-padding-xx-small @ib-padding-base;
      background-color: @ib-white-color;

      &__text {
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-x-large;
        color: @ib-font-color-base;
      }
    }
  }

  &__attachments {
    display: flex;
    flex-wrap: wrap;
    gap: @ib-padding-xx-small;

    &__item {
      border: 1px solid @gray-gray-100;
      border-radius: @ib-border-radius-xx-large;
      padding: @ib-padding-xx-small @ib-padding-base;
      background-color: @ib-white-color;

      a {
        display: flex;
        align-items: center;
        gap: @ib-padding-xxxx-small;
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-x-large;
      }
    }
  }

  &__variable {
    &__name {
      font-weight: @ib-font-weight-bold;
      color: @ib-font-color-secondary;
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-x-large;
    }

    &__value {
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-medium;
      border-radius: @ib-border-radius-x-small;
      background-color: @ib-white-color;
      color: @ib-font-color-base;
      padding: @ib-padding-xxxxx-small @ib-padding-xx-small;

      pre {
        margin: 0;
        max-height: 640px;
        white-space: break-spaces;
        word-break: break-all;
      }
    }
  }

  &__intent {
    display: flex;
    justify-content: space-between;
    border: 1px solid @gray-gray-100;
    border-radius: @ib-border-radius-x-small;
    padding: @ib-padding-base;
    margin-bottom: @ib-padding-xx-small;
    background-color: @ib-white-color;

    &__name {
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-x-large;
      font-weight: @ib-font-weight-bold;
      color: @ib-font-color-secondary;
    }

    &__score {
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-x-large;
      color: @ib-primary-color;
    }
  }

  &__kb-answer {
    border: 1px solid @gray-gray-100;
    border-radius: @ib-border-radius-x-small;
    padding: @ib-padding-base;
    margin-bottom: @ib-padding-xx-small;
    background-color: @ib-white-color;

    &__item {
      display: flex;
      justify-content: space-between;

      &__name {
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-x-large;
        font-weight: @ib-font-weight-bold;
        color: @ib-font-color-secondary;
      }

      &__score {
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-x-large;
        color: @ib-primary-color;
      }
    }

    &__description {
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-medium;
      color: @ib-font-color-base;
    }

    &__divider {
      background: @gray-gray-100;
      height: 2px;
      margin: @ib-padding-base 0;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;