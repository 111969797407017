@import "../../../../styles/variables";

.ib-dialog-session-status {
  display: flex;
  justify-content: center;

  &__text {
    border: 1px solid @gray-gray-100;
    border-radius: 70px;
    padding: 0 @ib-padding-small;
    margin: @ib-padding-base;
    font-size: @ib-font-size-x-small;
    line-height: @ib-line-height-base;
    color: @ib-font-color-tertiary;

    &_closed {
      display: flex;
      align-items: center;
      gap: @ib-padding-xxxxx-small;
      background-color: @gray-gray-50;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;