@import "../../styles/variables";

.ib-dialogs-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;

  &__header {
    background: @ib-white-color;
    box-shadow: @ib-border-bottom-box-shadow;
    display: flex;
    padding: @ib-padding-xx-small @ib-padding-xx-large;
    gap: @ib-padding-base;

    &__back-button {
      align-self: center;

      .ib-icon {
        width: 24px !important;
        height: 24px !important;
      }
    }

    &__content {
      flex-grow: 1;

      &__title {
        display: flex;
        gap: @ib-padding-xx-small;
        align-items: center;
        font-size: @ib-font-size-x-large;
        line-height: @ib-line-height-xx-large;
        font-weight: @ib-font-weight-bold;
        color: @ib-font-color-base;

        .ib-tag {
          background: @blue-blue-50;
          color: @ib-font-color-base;
          font-size: @ib-font-size-base;
          line-height: @ib-line-height-large;
        }
      }

      &__description {
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-medium;
        color: @ib-font-color-secondary;
      }
    }

    &__update-button {
      align-self: center;
    }
  }

  &__content-row {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    min-height: 0;

    &__dialog-list {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 70px);
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;