@import "../../../../styles/variables";

.ib-dialog-message {
  &__user-message-container {
    display: flex;
    max-width: 80%;
    margin: @ib-padding-xxxx-small 0;

    &__message {
      border-radius: @ib-border-radius-x-large;
      background-color: @gray-gray-100;
      cursor: pointer;
      padding: @ib-padding-xx-small @ib-padding-base;
      color: @ib-font-color-base;

      &_selected {
        background-color: @green-green-100;
      }

      &__text {
        display: inline;
        word-break: break-word;
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-base;

        p {
          display: inline;
          margin: 0;
        }
      }

      &__time {
        float: right;
        margin-left: @ib-padding-xx-small;
        margin-top: @ib-padding-xxxxx-small;
        color: @ib-font-color-tertiary;
        font-size: @ib-font-size-x-small;
        line-height: @ib-line-height-base;
      }
    }
  }

  &__bot-message-container, &__operator-message-container {
    display: flex;
    justify-content: flex-end;
    margin: @ib-padding-xxxx-small 0;

    &__message {
      border-radius: @ib-border-radius-x-large;
      background-color: @blue-blue-100;
      padding: @ib-padding-xx-small @ib-padding-base;
      max-width: 80%;
      cursor: pointer;
      color: @ib-font-color-base;

      &_selected {
        background-color: @green-green-100;
      }

      &__text {
        display: inline;
        word-break: break-word;
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-base;

        p {
          display: inline;
          margin: 0;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__time {
        float: right;
        margin-left: @ib-padding-xx-small;
        margin-top: @ib-padding-xxxxx-small;
        color: @ib-font-color-tertiary;
        font-size: @ib-font-size-x-small;
        line-height: @ib-line-height-base;
      }
    }
  }

  &__operator-message-container_internal > &__operator-message-container__message {
    background-color: @orange-orange-100;
    color: @ib-font-color-base;

    &_selected {
      background-color: @green-green-100;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;