@import "../../../../styles/variables";

.ib-dialog-event {
  display: flex;
  align-items: center;
  gap: @ib-padding-xxxx-small;
  font-size: @ib-font-size-x-small;
  line-height: @ib-line-height-base;
  color: @gray-gray-300;

  button {
    font-size: @ib-font-size-x-small;
    font-weight: @ib-font-weight-normal;
  }

  &_end {
    justify-content: flex-end;
  }

  &_success > span {
    color: #1CBF4A !important;
  }

  &_error > span {
    color: @red-red-600 !important;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;