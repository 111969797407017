@import "../../../styles/variables";

.ib-dialog-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: @ib-white-color;

  &__search {
    padding: @ib-padding-large @ib-padding-large @ib-padding-xx-small @ib-padding-large;

    &__filter-button {
      cursor: pointer;
      display: flex;
      align-items: center;

      .ib-icon.badge::after {
        position: absolute;
        content: '';
        background: @ib-primary-color;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        transform: translate(150%, 0);
      }
    }
  }

  &__list {
    overflow: auto;
    height: 100%;
    padding: 0 @ib-padding-xx-small;

    .ant-table-cell {
      border-bottom: 0;
      border-radius: @ib-border-radius-x-small;
    }

    .ant-table-selection-column {
      display: none;
    }

    .ant-table-row > td {
      border-bottom: 2px solid @ib-white-color;
    }

    tr.ant-table-row-selected > td {
      background: @blue-blue-50 !important;
      border-bottom: 2px solid @ib-white-color;
    }

    .ant-table-row:hover td {
      background: @blue-blue-50 !important;
    }

    .sb-scroll {
      padding: unset;
    }
  }

  &__export {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;
    padding: @ib-padding-large;
    background: @ib-white-color;
    border-radius: @ib-border-radius-x-small;
    box-shadow: @ib-box-shadow-default;

    :first-child {
      flex-shrink: 0;
    }

    &__count {
      line-height: @ib-line-height-medium;
      font-size: @ib-font-size-base;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: @ib-padding-xxxx-small;
    }

    &__buttons {
      display: flex;
      gap: @ib-padding-base;
    }

    .ib-icon {
      color: @ib-primary-color !important;
    }
  }

  &__filter-menu {
    width: 400px;

    .ant-popover-inner {
      border: 1px solid @gray-gray-100;
      border-radius: @ib-border-radius-small;
      box-shadow: @ib-box-shadow-default;
    }

    .ant-popover-inner-content {
      padding: @ib-padding-medium;

      .ib-select {
        margin-top: @ib-padding-xx-small;
        margin-bottom: @ib-padding-large;
      }

      .sb-range-picker {
        margin-bottom: @ib-padding-large;
        margin-top: @ib-padding-xx-small;
        height: 50px;
      }
    }

    &__buttons {
      display: inline-flex;

      .ib-button:first-child {
        margin-right: @ib-padding-small;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;